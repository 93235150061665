import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);


const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				lightest: "#f7f7f7",
				primary: "#73b541",
				secondary: "#b0bec5",
				accent: "#009FE3",
				error: "#b71c1c",
				green: "#00775B",
			},
		},
	},
});
export default vuetify;
