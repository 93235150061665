import { arrayUnion } from "vuex-easy-firestore";
import { arrayRemove } from "vuex-easy-firestore";
import Vue from "vue";

export default {
	firestorePath: "videoRooms/{id}",
	firestoreRefType: "doc",
	moduleName: "videoRoom",
	statePropName: "data",
	namespaced: true,
	state: {
		remote_participants: [],
		video_tracks: [],
		audio_tracks: [],
		main_track: null,
		local_video_track: null,
		local_screen_track: null,
	},
	mutations: {
		MAKE_MAIN(state, content) {
			Vue.set(state, "main_track", content);
		},
		STOP_MAIN(state) {
			Vue.set(state, "main_track", null);
		},
		REMOTE_PARTICIPANT_ENTERS(state, identity) {
			let remote_participants = state.remote_participants;
			remote_participants.push(identity);
			Vue.set(state, "remote_participants", remote_participants);
		},
		REMOTE_PARTICIPANT_LEAVES(state, identity) {
			let remote_participants = state.remote_participants;
			remote_participants = remote_participants.filter(
				(a) => a !== identity
			);
			Vue.set(state, "remote_participants", remote_participants);
			Vue.set(state, "video_tracks", state.video_tracks.filter( a => a.participant !== identity ));
			Vue.set(state, "audio_tracks", state.audio_tracks.filter( a => a.participant !== identity ));
		},
		START_TRACK(state, track) {
			let arr = state[`${track.kind}_tracks`];
			arr.push(track);
			Vue.set(state, `${track.kind}_tracks`, arr);
		},
		STOP_TRACK(state, track) {
			let arr = state[`${track.kind}_tracks`];
			arr = arr.filter((a) => a.sid !== track.trackSid);
			Vue.set(state, `${track.kind}_tracks`, arr);
		},
		START_LOCAL_VIDEO(state, track) {
			Vue.set(state, "local_video_track", track);
		},
		STOP_LOCAL_AUDIO(state, track) {
			Vue.set(state, "local_audio_track", track);
		},
		STOP_LOCAL_VIDEO(state) {
			Vue.set(state, "local_video_track", null);
		},
		START_SCREENSHARE(state, track) {
			Vue.set(state, "local_screen_track", track);
		},
		STOP_SCREENSHARE(state) {
			Vue.set(state, "local_screen_track", null);
		},
	},
	getters: {
		participantAudio(state) {
			return (id) => {
				return state.audio_tracks.filter((a) => a.participant == id);
			};
		},
		participantVideo(state) {
			return (id) => {
				return state.video_tracks.filter((a) => a.participant == id);
			};
		},
		localVideos(state) {
			let tracks = [];
			if (state.local_video_track) {
				tracks.push(state.local_video_track);
			}
			if (state.local_screen_track) {
				tracks.push(state.local_screen_track);
			}
			return tracks;
		},
		isGuest(state, _, rootState) {
			const user = rootState.auth.data;
			return state.data.guests.includes(user.id);
		},
		canAccess(state, _, rootState) {
			const user = rootState.auth.data;
			if (!state.data || !state.data.guests) {
				return false;
			}
			if (state.data.guests && state.data.guests.includes(user.id)) {
				return true;
			}
		},
	},
	actions: {
		unsetMain({ commit, state }, track) {
			if (state.main_track && state.main_track.track) {
				if (state.main_track.track.sid == track.trackSid) {
					commit("STOP_MAIN");
				}
			}
		},
		stopLocalVideo({ commit, dispatch, state }) {
			if (state.local_video_track) {
				dispatch("unsetMain", state.local_video_track);
				state.local_video_track.stop();
			}
			commit("STOP_LOCAL_VIDEO");
		},
		stopScreenshare({ commit, dispatch, state }) {
			if (state.local_screen_track) {
				state.local_screen_track.stop();
				dispatch("unsetMain", state.local_screen_track);
			}
			commit("STOP_SCREENSHARE");
		},
		stopLocalAudio({ commit, state }) {
			if (state.local_audio_track) {
				state.local_audio_track.stop();
			}
			commit("STOP_LOCAL_AUDIO");
		},
		fetchRoom({ dispatch }, id) {
			dispatch("openDBChannel", { pathVariables: { id } }).then(() => {
				dispatch("enterWaitingRoom");
			});
		},
		start({ dispatch }, id) {
			dispatch("fetchRoom", id);
		},
		enterWaitingRoom({ dispatch, rootState, getters }) {
			let user = rootState.auth.data;
			if( !user.id ){
				return;
			}
			let info = "Guest";
			if (user.name) {
				info = `${user.name} (${user.email})`;
			}

			if (!getters.isGuest) {
				dispatch("patch", {
					waiting_room: arrayUnion(user.id),
					identities: {
						[user.id]: info,
					},
				});
			} else {
				dispatch("enterConference");
			}
		},
		enterConference({ dispatch, rootState }) {
			dispatch("patch", {
				members: arrayUnion(rootState.auth.data.id),
			});
		},
		leaveConference({ dispatch, rootState, state }) {
			dispatch("patch", { members: arrayRemove(rootState.auth.data.id) });
			if (state.data.moderator == rootState.auth.data.id) {
				dispatch("patch", { moderator: null });
			}
		},
	},
};
