<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		:mini-variant="mini"
		permanent
		style="z-index: 100"
	>
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item
				:expanded="!mini"
				v-for="item in items"
				:key="item.title"
				:link="item.link"
				v-bind="item"
			>
			</nav-item>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<nav-item
					:expanded="!mini"
					link="/contact"
					icon="mdi-at"
					text="Contact Us"
				></nav-item>
				<chat :expanded="!mini"></chat>
				<logout :expanded="!mini" v-if="loggedIn"></logout>
				<nav-item
					@click="mini = !mini"
					:expanded="!mini"
					text="Collapse Menu"
					:icon="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
import Chat from "@c/ui/Chat.vue";
import LoggedIn from "@c/auth/LoggedIn";
import Login from "@c/auth/Login";
import Logout from "@c/auth/Logout.vue";
import NavItem from "@c/navigation/Item";

export default {
	name: "Sidebar",
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
	data() {
		return {
			drawer: true,
			items: [
				{ text: "Home", icon: "mdi-home", link: "/" },
				{ text: "About", icon: "mdi-information", link: "/about" },
			],
			mini: false,
		};
	},
	components: {
		Login,
		Chat,
		LoggedIn,
		Logout,
		NavItem,
	},
};
</script>
