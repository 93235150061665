import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import auth from "./modules/auth.js";
import videoRoom from "./modules/videoRoom";

const vxf = VuexEasyFirestore([auth, videoRoom], {
	logging: true,
	FirebaseDependency: firebase,
});

const mwstoreutils = {
	actions: {
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;
