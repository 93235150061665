<template>
	<v-app-bar app flat color="transparent">
		<v-toolbar-title class="font-weight-light d-none d-md-block">{{siteTitle}}</v-toolbar-title>
		<v-spacer></v-spacer>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
export default {
	name: "TopNav",
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
	inject: ["isMobile"],
	computed: {
		height() {
			return this.isMobile ? 50 : 80;
		}, 
		siteTitle(){
			return this.$store.state.site_name;
		}
	}
};
</script>
