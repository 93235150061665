import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () =>
			import("../views/Home.vue"),
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/video/:id",
		name: "Video",
		props: true,
		// meta: {
		// 	requires_auth: true
		// },
		component: () =>
			import( "../views/Video.vue"),
	},

	
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
