<template>
	<v-app>
		<top-nav></top-nav>
		<sidebar></sidebar>
		<v-main>
			<v-sheet color="transparent" flat class="pa-8">
				<router-view />
			</v-sheet>
			
		</v-main>
	</v-app>
</template>

<style lang="less">
	@import url("./assets/css/main.less");
</style>

<script>
import Vuex from "vuex";
import TopNav from "@c/navigation/Top.vue";
import Sidebar from "@c/navigation/Side.vue";
export default {
	name: "App",
	data: () => ({}),
	computed: {
		...Vuex.mapState({
			state: (state) => state,
		}),
		isMobile(){
			return this.$vuetify.breakpoint.smAndDown
		}
	},
	components: {
		TopNav,
		Sidebar,
	},
	provide() {
		return {
			isMobile: this.isMobile
		}
	},
	created(){
		document.title = this.$store.state.site_name;
	}
};
</script>
